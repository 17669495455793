import "./NewsKor.css";

import { useParams } from "react-router-dom";
import { v4 } from "uuid";

import { post_kr } from "../../common/contents/kr";
import { useEffect, useState } from "react";

function NewsKor1({ prop1 }) {
  const { newsId } = useParams();

  const [obj, setObj] = useState(null);

  useEffect(() => {
    if (!obj) {
      setObj(post_kr[newsId]);
    }

    document.title = "우장일보";
  }, [obj, newsId]);

  // * COMPONENT
  const BB = ({ str, type = 0, extraClass }) => {
    return (
      <div className={`bb ${extraClass ? extraClass : ""}`}>
        {type === 1 ? <div className="st">▶</div> : <div className="sq"></div>}
        <div className="tt">{str}</div>
      </div>
    );
  };

  const NewsKrBody = ({ obj }) => {
    const autofillId = `neb_auto_${v4()}`;

    const [y, m, d] = obj.date.split("-");

    useEffect(() => {
      document.getElementById(autofillId).innerHTML = obj.body;
    });

    return (
      <div className="main">
        <div className="category">
          홈<span>{">"}</span>
          뉴스
          <span>{">"}</span>
          사회
        </div>

        <div className="btns">
          <div className="btn">
            <img src="/images/kr1/btns/1.gif" alt="g" />
            <div className="tt">글자 크게</div>
          </div>
          <div className="btn">
            <img src="/images/kr1/btns/2.gif" alt="g" />
            <div className="tt">글자 작게</div>
          </div>
          <div className="btn">
            <img src="/images/kr1/btns/3.gif" alt="g" />
            <div className="tt">댓글</div>
          </div>
          <div className="btn">
            <img src="/images/kr1/btns/4.gif" alt="g" />
            <div className="tt">다운로드</div>
          </div>
          <div className="btn">
            <img src="/images/kr1/btns/5.gif" alt="g" />
            <div className="tt">스크랩</div>
          </div>
          <div className="btn">
            <img src="/images/kr1/btns/6.gif" alt="g" />
            <div className="tt">이메일</div>
          </div>
          <div className="btn">
            <img src="/images/kr1/btns/7.gif" alt="g" />
            <div className="tt">프린트</div>
          </div>
        </div>

        <div className="title">{obj.title}</div>

        <div className="editor">{`${obj.editor}`}</div>

        <div className="date">{`입력 : ${y}. ${m}. ${d}.`}</div>

        <div className="post" id={autofillId}>
          {/* auto fill */}
        </div>
      </div>
    );
  };

  return (
    <div className="NewsKor1">
      <div className="nk_con">
        <div className="head">
          <div className="ib">
            <img src="/images/kr1/head/1.gif" alt="ib" />
          </div>
          <div className="ib">
            <img src="/images/kr1/head/2.gif" alt="ib" />
          </div>
          <div className="ib">
            <img src="/images/kr1/head/3.gif" alt="ib" />
          </div>
          <div className="ib">
            <img src="/images/kr1/head/4.gif" alt="ib" />
          </div>
          <div className="ib">
            <img src="/images/kr1/head/5.gif" alt="ib" />
          </div>
          <div className="ib">
            <img src="/images/kr1/head/6.gif" alt="ib" />
          </div>

          <div className="fBlank">
            <div className="fBlank"></div>

            <div className="bb">ㆍ로그인</div>
            <div className="bb">ㆍ회원가입</div>
            <div className="bb">ㆍID/비밀번호 찾기</div>
          </div>
        </div>

        <div className="top">
          <div className="fBlank">
            <img src="/images/kr1/logo.png" alt="" />
          </div>
          <div className="promo fBlank">
            <div className="p1">
              Promotion <span>▶</span>
            </div>
            <div className="p2">
              ㆍ우장일보와 메가무비가
              <span>
                영화예매권 <b>1000장</b>을 쏜다!
              </span>
            </div>
            <div className="p3">
              <span className="s1">ㆍ최고급카메라 25대와 함께</span>
              <span className="s2"> 당신을 응원합니다!</span>
            </div>
          </div>
          <div
            className="fBlank"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <img style={{height: "60px", cursor: "pointer", userSelect: "none"}} src="/images/kr1/head/search.png" alt="se" />
            <input
              style={{
                height: "28px",
                boxSizing: "border-box",
                border: "solid 3px var(--color-kr2)"
              }}
              type="text"
            />
          </div>
        </div>

        <div className="body">
          <div className="left">
            <div className="title">
              <img src="/images/kr1/left_btn_1.png" alt="sib" />
            </div>

            <div className="block b1">
              <div className="two">
                <BB str={`정치`} />
                <BB str={`경제`} />
              </div>
              <div className="two">
                <BB str={`사회`} />
                <BB str={`문화`} />
              </div>
              <BB str={`국제`} />
              <div className="two">
                <BB str={`연예`} />
                <BB str={`스포츠`} />
              </div>
              <BB str={`다큐ㆍ기획`} />
              <BB str={`플래시뉴스`} />
              <BB str={`스타이슈`} />
            </div>

            <div className="block b2">
              <BB str={`나우코리아`} type={1} />
              <BB str={`스타ING`} type={1} />
              <BB str={`킥잇 재키우`} type={1} />
              <BB str={`Review 우장`} type={1} />
              <BB str={`기울어진 영화보기`} type={1} />
              <BB str={`주간 포토뉴스`} type={1} />
              <BB str={`클릭 연예`} type={1} />
              <BB str={`Biz News`} type={1} />
              <BB str={`Book Ujang`} type={1} />
              <BB str={`헬스강좌 - 명의`} type={1} extraClass={`end`} />
            </div>

            <div className="block b3">
              <div className="title">Business & ON AIR</div>
              <div className="box">
                <div className="bb str">Global</div>
                <div className="bb">Success</div>
                <div className="bb">Money</div>
                <div className="bb">Life style</div>
                <div className="bb end">Special</div>
              </div>
              <div className="foot">
                <div className="fBlank">요일별 편성표</div>
                <div style={{ fontSize: "8px", color: "blue" }}>▶</div>
              </div>
            </div>
          </div>

          <div className="right">
            {obj ? (
              <>
                <NewsKrBody obj={obj} />
                <div className="foot">
                  <div className="btn">
                    <img src="/images/kr1/btns/3.gif" alt="g" />
                    <div className="tt">댓글 작성</div>
                  </div>
                  <div className="btn">
                    <img src="/images/kr1/btns/5.gif" alt="g" />
                    <div className="tt">스크랩</div>
                  </div>
                  <div className="btn">
                    <img src="/images/kr1/btns/6.gif" alt="g" />
                    <div className="tt">이메일</div>
                  </div>
                  <div className="btn">
                    <img src="/images/kr1/btns/7.gif" alt="g" />
                    <div className="tt">프린트</div>
                  </div>
                  <div className="fBlank"></div>
                  <div className="btn">
                    <img src="/images/kr1/btns/9.gif" alt="g" />
                    <div className="tt">기사목록</div>
                  </div>
                  <div className="btn end">
                    <img src="/images/kr1/btns/8.gif" alt="g" />
                    <div className="tt">맨위로</div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsKor1;
