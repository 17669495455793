import { imageLine } from "./en";

const post_kr = {
  article000603a34c: {
    title: `‘중앙지검의 무리한 가림시장 수사. 그날 밤 가림시장에서 무슨 일이?’`,
    editor: `장석 기자 : longstone@ujangnews.com`,
    head: `중앙지검이 서울 가림시장을 기습 방문해 불법 유통 행위를 조사한다며 창고와 상점 등 가림시장 곳곳을 수색했다. 그러나 사전 경고 없이 이루어진 이번 수사는 현장에서 일하던 상인들과 농민들에게 큰 혼란과 분노를 안겼다. 검찰은 시장 곳곳의 농산물을 뒤엎고 창고를 무단으로 열며 강압적인 조사를 이어갔다. 이에 상인들은 강하게 항의했지만, 수사팀은 이를 묵살하며 조사를 강행했고, 현장은 순식간에 아수라장이 되었다. 예고 없이 찾아와 우리 농민들의 피와 땀이 서린 농산물을 짓밟고 헤집어 놓은 행동은 과거 폭력과 고문으로 얼룩진 야만의 시대를 연상케 했다. 수사를 진행하던 도중, 기자는 검찰 측이 항의하는 상인을 밀치고, 때리려는 듯한 위협적인 행동까지 하는 모습을 목격했다. 검찰의 이러한 태도는 상인들의 반발을 더욱 키웠고, 시장 전체가 긴장감에 휩싸였다.`,
    date: `2008-06-05`,
    body: `
    <div class="news_imagebox centre">
      <img src="/images/kojin/chroma_gray.png" alt="img" />
    </div>
    <p>중앙지검이 서울 가림시장을 기습 방문해 불법 유통 행위를 조사한다며 창고와 상점 등 가림시장 곳곳을 수색했다. 그러나 사전 경고 없이 이루어진 이번 수사는 현장에서 일하던 상인들과 농민들에게 큰 혼란과 분노를 안겼다. 검찰은 시장 곳곳의 농산물을 뒤엎고 창고를 무단으로 열며 강압적인 조사를 이어갔다. 이에 상인들은 강하게 항의했지만, 수사팀은 이를 묵살하며 조사를 강행했고, 현장은 순식간에 아수라장이 되었다.</p>
    <p>예고 없이 찾아와 우리 농민들의 피와 땀이 서린 농산물을 짓밟고 헤집어 놓은 행동은 과거 폭력과 고문으로 얼룩진 야만의 시대를 연상케 했다. 수사를 진행하던 도중, 기자는 검찰 측이 항의하는 상인을 밀치고, 때리려는 듯한 위협적인 행동까지 하는 모습을 목격했다. 검찰의 이러한 태도는 상인들의 반발을 더욱 키웠고, 시장 전체가 긴장감에 휩싸였다.</p>
    <p>수사팀이 창고와 매대를 뒤지며 훼손한 농산물은 단순히 상품 가치를 잃는 데 그치지 않았다. 수십 박스의 농산물이 바닥에 흩어졌고, 일부는 심하게 훼손되어 더 이상 판매가 불가능한 상태가 되었으며, 상인들이 공들여 정리해둔 매대와 포장물까지 무너져 내렸다. </p>
    <p>현장에서 기자가 중앙지검 수사팀에 질문을 던졌으나, 답변은 돌아오지 않았다. 무슨 이유로 가림시장에 온 것인지, 이렇게까지 상인들에게 피해를 끼친 상황에서 피해보상은 따로 할 것인지 묻는 기자의 질문에 수사팀은 답변을 피한 채 서둘러 자리를 떠났다. 급히 차량에 올라 현장을 빠져나가는 모습은 상인들에게 더욱 큰 분노를 남겼다.</p>
    <p>이 사건은 한미 FTA 소고기 협상 이후 확산된 국민적 불안과 무관하지 않다. 광화문 일대에서 벌어진 촛불시위는 안전하지 않은 미국산 소고기 수입 문제로 인해 국민들이 생명과 먹거리에 대해 느낀 깊은 불신의 표현이다. 정부는 협상 과정에서 충분한 설명과 설득 대신, 신뢰를 저버리는 모습을 보였고, 이는 시민들과 축산업 종사자 모두에게 생존권과 안전에 대한 큰 위협으로 다가왔다. 이번 검찰 수사에서도 상황은 크게 다르지 않다. 정부는 한미 FTA 협상에서 먹거리 안전과 관련된 국민적 우려를 외면했고, 이번 사건에서 검찰은 상인들과 시장의 현실을 무시한 채 강압적인 방식으로 수사를 강행한 것이다. 이는 가림시장 상인들에게 자신들의 생업이 존중받지 못하고 있다는 깊은 상실감을 남겼을 것이다.</p>
    <p>축산업과 농산물 유통 현장은 국민들의 먹거리 안전과 직접 연결된 민감한 분야다. 하지만 정부와 검찰이 보여준 태도는 이런 현실을 전혀 반영하지 못한 채, 국민들이 느끼는 불안과 불신을 다시금 증폭시키고 있다. 이러한 행동은 생업의 터전과 신뢰를 훼손하고, 정부가 국민과의 약속을 얼마나 존중하는지에 대해 근본적인 의문을 제기하게 한다.</p>
    <p>현재까지 시장의 피해는 상당하지만, 중앙지검은 피해보상이나 사후 대책에 대해 어떤 언급도 하지 않고 있다. 이에 상인들은 "수사라는 이름으로 우리의 생업을 망가뜨려 놓고 아무런 책임을 지지 않는 것이 말이 되느냐"며 울분을 토로했다. </p>
    <p>가림시장은 국내 농산물 유통의 중심지로, 하루 수천 명이 거래를 위해 찾는 중요한 공간이다. 그러나 검찰은 이러한 현실을 고려하지 않은 채 무리한 방식으로 수사를 진행하며 시장의 경제적, 물리적 기반에 큰 타격을 남겼다. 이번 사건은 단순히 가림시장 상인들의 피해로 끝나지 않는다. 국민의 생명과 연결된 먹거리 문제에서 신뢰를 잃는다면, 정부와 검찰은 더 큰 혼란과 저항을 마주하게 될 것이다.</p>
    `,
  },
};

export {
  post_kr
}