import React from 'react';
import ReactDOM from 'react-dom/client';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.css';

import App from './App';

import ErrorPage from './components/ErrorPage/ErrorPage';
import Oldgoo from './components/Oldgoo/Oldgoo';
import OldgooSearch from './components/Oldgoo/OldgooSearch';
import NewsEng1 from './components/News/NewsEng1';
import NewsChina1 from './components/News/NewsChina1';
import NewsKor1 from './components/News/NewsKor1';

const router = createBrowserRouter([
  {
    path: "/about",
    element: <App />,
    errorElement: <ErrorPage />
  },
  {
    path: "/",
    element: <Oldgoo />,
    errorElement: <ErrorPage />
  },
  {
    path: "/search",
    element: <OldgooSearch />,
    errorElement: <ErrorPage />
  },
  {
    path: "/en/:newsId",
    element: <NewsEng1 />,
    errorElement: <ErrorPage />
  },
  {
    path: "/cn/:newsId",
    element: <NewsChina1 />,
    errorElement: <ErrorPage />
  },
  {
    path: "/kr/:newsId",
    element: <NewsKor1 />,
    errorElement: <ErrorPage />
  },
])

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals